<template>
  <apex-charts-graph v-if="series[0].data.length > 0" :height=160 type="area" :series="series" :options="chartOptions">
  </apex-charts-graph>
  <div v-else class="w-full m-3 font-bold">
    <p>{{ chartOptions.title.text }}</p>
    <p class="text-center mt-5">No data</p>
  </div>
</template>

<script>
import ApexChartsGraph from './ApexChartsGraph.vue';

export default {
  name: 'DashboardCaseCountGraph',
  components: {
    ApexChartsGraph,
  },
  props: {
    series: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: '',
          margin: 30,
          offsetX: 10,
          style: {
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 'bold',
            fontFamily: 'Inter, sans-serif',
            color: '#111827',
          },
        },
        chart: {
          type: 'area',
          height: '1000px',
          background: '#F8FAFC',
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          }
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: false,
        },
        grid: {
          show: false,
        },
        stroke: {
          curve: 'smooth'
        },
        colors: ['#6A7780'],
        tooltip: {
          enabled: false,
        },
        xaxis: {
          labels: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false
        },
      }
    };
  },
  watch: {
    chartOptions: {
      handler() {
        this.chartOptions.title.text = this.series[0].name === 'covid' ? this.series[0].name.toUpperCase().concat('-19') : this.series[0].name.toUpperCase();
      },
      deep: true,
      // force eager callback execution
      immediate: true
    }
  }
};

</script>

<style lang="scss" scoped>

</style>